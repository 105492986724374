import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import VideoMdAkte from "../../assets/md-akte-video.mp4"
import VideoMdAkteIllu from "../../assets/MD-Akte-Modul-720px.mp4"


// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  // marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const MdAktePage = () => {
  return (
    <Layout pageTitle="Medizinische Dienste" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>

                <h1>Video MD Akte</h1>
                <p className="text-medium">
                health-engine® hat die ideale Basis für die umfassende Beantwortung von Anfragen seitens Medizinischer Dienst/LE-Portal.
                </p>

                <p><span className="text-blue">00:00</span> - Intro</p>
                <p><span className="text-blue">00:11</span> - Herausfoderungen der Medizin Controller </p>
                <p><span className="text-blue">00:30</span> - Was unsere Lösung enthält</p>
                <p><span className="text-blue">00:52</span> - Die Funktionsweise des Moduls exemplarisch erklärt</p>

                <video controls>
                  <source src={VideoMdAkteIllu} type="video/mp4"/>
                  <track src="captions_en.vtt" kind="captions" srclang="de" label="md-akte-deutsch"></track>
                </video>

                </div>

                <div className={col12}>
                <h2>Ergänzendes Material - die MD Akte in der Praxis</h2>
                <p className="text-medium">
                Das folgende Video zeigt das User Interface der MD Akte im Einsatz.
                </p>

                <video controls>
                  <source src={VideoMdAkte} type="video/mp4"/>
                  <track src="captions_en.vtt" kind="captions" srclang="de" label="md-akte-deutsch"></track>
                </video>
                </div>
            </div>



           
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default MdAktePage;
